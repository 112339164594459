import Amplify from '@aws-amplify/core';
import App from 'App';
import { AuthProvider } from 'components/AuthContext';
import { DataProvider } from 'components/DataContext';
import { HelpProvider } from 'components/HelpContext';
import Tracker from 'components/Tracker';
import * as config from 'config.json';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorker from 'util/serviceWorker';
import './index.css';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.AwsRegion,
    userPoolId: config.CognitoUserPoolId,
    identityPoolId: config.CognitoIdentityPoolId,
    userPoolWebClientId: config.CognitoAppClientId,
  },
});

ReactGA.initialize(config.GoogleAnalyticsId);

declare global {
  interface Window {
    logRenders: boolean;
    logStoreUpdates: boolean;
  }
}

ReactDOM.render(
  <Router>
    <Tracker>
      <AuthProvider>
        <DataProvider>
          <HelpProvider>
            <App />
          </HelpProvider>
        </DataProvider>
      </AuthProvider>
    </Tracker>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
