export default class Path {
  public visible: boolean;
  public path: string;
  public title?: string;
  public children: any;
  public wildkids = false;

  constructor(visible: boolean, path: string, title?: string) {
    this.visible = visible;
    this.path = path;
    this.title = title;
    this.children = {};
  }

  public createChild(visible: boolean, path: string, title?: string) {
    const result = new Path(visible, path, title);
    if (path === '?') {
      this.wildkids = true;
    }
    this.children[path] = result;
    return result;
  }

  public get(child: string) {
    if (this.wildkids) {
      return this.children['?'];
    }
    return this.children[child];
  }
}
