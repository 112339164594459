import { Theme } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { DataAwareProps, withData } from 'components/DataContext';
import { observer } from 'mobx-react';
import React from 'react';

const styles = (theme: Theme) =>
  createStyles({
    close: {
      padding: theme.spacing(0.5),
    },
  });

interface QueuedSnackbarProps extends WithStyles<typeof styles>, DataAwareProps {}

@withData
@observer
class QueuedSnackbar extends React.Component<QueuedSnackbarProps> {
  public handleClose = (event: any, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.props.data.snackbar.onClose();
  };

  public handleExited = () => {
    this.props.data.snackbar.onExited();
  };

  public render() {
    const { classes, data } = this.props;
    const { messageInfo, open } = data.snackbar;

    return (
      <Snackbar
        key={messageInfo.key}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={this.handleClose}
        onExited={this.handleExited}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{messageInfo.message}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={this.handleClose}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
    );
  }
}

export default withStyles(styles)(QueuedSnackbar);
