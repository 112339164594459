import Auth from '@aws-amplify/auth';
import * as config from 'config.json';
import Sockette from 'sockette';

export default class Connection {
  public store: any;
  public enabled = false;
  public connected = false;
  public connecting = false;
  //@ts-ignore
  private websocket: Sockette = null;
  constructor(store: any) {
    this.store = store;
  }

  public sendMessage = (message: any) => {
    if (!this.connected) {
      console.error('Disconnected: Send failed');
    } else {
      //@ts-ignore
      this.websocket.send(JSON.stringify(message));
    }
  };

  public handleLogout = async () => {
    this.enabled = false;
    if (this.websocket) {
      this.websocket.close();
    }
  };

  public handleLogin = async () => {
    //    this.enabled = true;
    //    this.connectWebsocket();
  };

  private connectWebsocket = async () => {
    if (!this.enabled || this.connecting || this.connected) {
      return;
    }
    this.connecting = true;

    const session = await Auth.currentSession();

    //@ts-ignore
    const token = session.idToken.jwtToken;
    const url =
      'wss://' +
      config.WssApiDomain +
      '/' +
      config.WssApiStage +
      '?token=' +
      token +
      '&username=none&password=none';
    //console.log('Creating websocket:', url);

    const connection = this;
    const store = this.store;
    this.websocket = new Sockette(url, {
      maxAttempts: -1,
      onopen(e: any) {
        console.log('Websocket connected:', e);
        connection.connected = true;
        connection.connecting = false;
        connection.websocket.send(JSON.stringify({ message: 'refresh' }));
      },
      onmessage(e: any) {
        const message = JSON.parse(e.data);
        if (message.message === 'update') {
          store.handleUpdate(message.data);
        } else if (message.message === 'log') {
          store.handleLog(message.data);
        } else if (message.message === 'broadcast') {
          //          alert(message.data);
        } else {
          console.error('Unknown message type:', message.message);
        }
      },
      onreconnect(e: any) {
        console.log('Websocket reconnecting:', e);
      },
      onclose(e: any) {
        console.log('Websocket closed:', e);
        connection.connected = false;
        connection.connecting = false;
        connection.connectWebsocket(); //re-open
      },
      onerror(e: any) {
        console.error('Websocket error:', e);
        //        connection.connected = false;
        //        connection.connecting = false;
      },
    });
  };
}
