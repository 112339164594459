import { Theme } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { DataAwareProps, withData } from 'components/DataContext';
import { observer } from 'mobx-react';
import React from 'react';

const styles = (theme: Theme) =>
  createStyles({
    a: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      link: theme.palette.primary.main,
      visited: theme.palette.primary.main,
      hover: theme.palette.primary.light,
      active: theme.palette.primary.dark,
    },
  });

interface EmailLinkProps extends WithStyles<typeof styles>, DataAwareProps {
  address?: string;
  user?: string;
  domain?: string;
  color?: TypographyProps['color'];
  variant?: TypographyProps['variant'];
  TypographyClasses?: any;
}

@withData
@observer
class EmailLink extends React.Component<EmailLinkProps> {
  private url: string;
  private address: string;
  constructor(props: EmailLinkProps) {
    super(props);
    const { address, user, domain } = this.props;
    if (address) {
      this.address = address;
    } else if (user) {
      if (domain) {
        this.address = user + '@' + domain;
      } else {
        this.address = user + '@hogge.engineering';
      }
    } else {
      this.address = 'hello@example.com';
    }
    this.url = 'mailto:' + this.address;
  }

  public getName() {
    return this.address;
  }

  public renderChildren() {
    const { children } = this.props;
    return children
      ? children
      : this.shouldUseTypography()
      ? this.renderTypography()
      : this.getName();
  }

  public shouldUseTypography() {
    return this.props.variant || this.props.color || this.props.TypographyClasses;
  }

  public renderTypography() {
    const { variant, color, TypographyClasses } = this.props;
    return (
      <Typography
        variant={variant}
        color={color}
        display="block"
        className={classNames(TypographyClasses)}
        component="span"
        noWrap={true}>
        {this.getName()}
      </Typography>
    );
  }

  public render() {
    return (
      <Link href={this.url} className={this.props.classes.a}>
        {this.renderChildren()}
      </Link>
    );
  }
}

export default withStyles(styles)(EmailLink);
