import { Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import React from 'react';

import LogoFB from 'images/social/facebook.svg';
import LogoIG from 'images/social/instagram.svg';
import LogoLI from 'images/social/linkedin.svg';
import LogoTwitter from 'images/social/twitter.svg';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: 'auto',
      margin: `${theme.spacing(5)}px ${theme.spacing(3)}px ${theme.spacing(2)}px`,
      padding: `${theme.spacing(3)}px 0`,
      borderTop: `1px solid ${theme.palette.divider}`,
      [theme.breakpoints.up('md')]: {
        width: 800,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      [theme.breakpoints.up('lg')]: {
        width: 1000,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    logo: {
      display: 'inline-block',
      height: 32,
      width: 'auto',
    },
    links: {},
    link: {
      color: 'inherit',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  });

interface SocialBarProps extends WithStyles<typeof styles> {}

class SocialBar extends React.Component<SocialBarProps> {
  public render() {
    const { classes } = this.props;

    return (
      <Box m={2}>
        <Grid container={true} justify="center" spacing={4}>
          <Grid item={true}>
            <Link href="https://www.instagram.com/hogge.engineering/" target="_blank">
              <img src={LogoIG} className={classes.logo} alt="Hogge Engineering on Instagram" />
            </Link>
          </Grid>
          <Grid item={true}>
            <Link href="https://www.linkedin.com/company/hogge-engineering/" target="_blank">
              <img src={LogoLI} className={classes.logo} alt="Hogge Engineering on LinkedIn" />
            </Link>
          </Grid>
          <Grid item={true}>
            <Link href="https://www.facebook.com/hogge.engineering/" target="_blank">
              <img src={LogoFB} className={classes.logo} alt="Hogge Engineering on Facebook" />
            </Link>
          </Grid>
          <Grid item={true}>
            <Link href="https://twitter.com/HoggeEngineerin" target="_blank">
              <img src={LogoTwitter} className={classes.logo} alt="Hogge Engineering on Twitter" />
            </Link>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default withStyles(styles)(SocialBar);
