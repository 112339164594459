import { Theme } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { DataAwareProps, withData } from 'components/DataContext';
import Page from 'components/Page';
import { RouteComponentProps } from 'react-router';

import CareersImage from 'images/NavDrawer/careers.svg';
import PressImage from 'images/NavDrawer/press.svg';
import ProjectsImage from 'images/NavDrawer/projects.svg';

import IconButton from '@material-ui/core/IconButton';
import HyperLink from 'components/Links/HyperLink';

import HoggeToggle from 'components/NavBar/HoggeToggle';

const styles = (theme: Theme) => createStyles({});

interface HomeProps extends WithStyles<typeof styles>, RouteComponentProps<{}>, DataAwareProps {
  match: any;
}

@withData
class Home extends React.Component<HomeProps> {
  public render() {
    //    const { classes } = this.props;

    return (
      <Page disableCrumbs={true} title="Hogge Engineering | San Diego, CA">
        <Grid container={true} spacing={0} alignItems="center" justify="center" direction="column">
          <Grid item={true}>
            <HoggeToggle size={250} />
          </Grid>

          <Grid item={true}>
            <Grid container={true} spacing={3} alignItems="center" justify="center">
              <Grid item={true}>
                <IconButton>
                  <HyperLink to="/careers">
                    <Grid
                      container={true}
                      spacing={0}
                      alignItems="center"
                      justify="center"
                      direction="column">
                      <Grid item={true}>
                        <img width={64} src={CareersImage} alt="Careers" />
                      </Grid>
                      <Grid item={true}>
                        <Typography color="primary" align="center" variant="button">
                          Careers
                        </Typography>
                      </Grid>
                    </Grid>
                  </HyperLink>
                </IconButton>
              </Grid>

              <Grid item={true}>
                <IconButton>
                  <HyperLink to="/projects">
                    <Grid
                      container={true}
                      spacing={0}
                      alignItems="center"
                      justify="center"
                      direction="column">
                      <Grid item={true}>
                        <img width={96} src={ProjectsImage} alt="Project Portal" />
                      </Grid>
                      <Grid item={true}>
                        <Typography color="primary" align="center" variant="button">
                          Project
                          <br />
                          Portal
                        </Typography>
                      </Grid>
                    </Grid>
                  </HyperLink>
                </IconButton>
              </Grid>

              <Grid item={true}>
                <IconButton>
                  <HyperLink to="/press">
                    <Grid
                      container={true}
                      spacing={0}
                      alignItems="center"
                      justify="center"
                      direction="column">
                      <Grid item={true}>
                        <img width={64} src={PressImage} alt="Press Releases" />
                      </Grid>
                      <Grid item={true}>
                        <Typography color="primary" align="center" variant="button">
                          Press
                        </Typography>
                      </Grid>
                    </Grid>
                  </HyperLink>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Page>
    );
  }
}

export default withStyles(styles)(Home);
