import { Theme } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import React from 'react';

import { AuthAwareProps, withAuth } from 'components/AuthContext';
import { HelpAwareProps, withHelp } from 'components/HelpContext';
import SideDrawer from './SideDrawer';

const styles = (theme: Theme) =>
  createStyles({
    appBar: {},
    flex: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      },
    },
    center: {
      flexGrow: 1,
      marginLeft: 32,
      // [theme.breakpoints.down('sm')]: {
      //   display: 'none',
      // },
    },
    iconContainer: {
      padding: `10px 0px`,
      // [theme.breakpoints.down('sm')]: {
      //   display: 'none',
      // },
    },
  });

interface NavBarProps extends WithStyles<typeof styles>, AuthAwareProps, HelpAwareProps {}

class NavBar extends React.Component<NavBarProps> {
  public handleHelp = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    this.props.handleHelp();
  };

  public render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <AppBar position="fixed" color="default" className={classes.appBar}>
          <Toolbar>
            <Grid container={true} spacing={3} alignItems="baseline">
              <Grid item={true} xs={12} className={classes.flex}>
                <SideDrawer />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Toolbar />
        <br />
      </React.Fragment>
    );
  }
}

export default withAuth(withHelp(withStyles(styles)(NavBar)));
