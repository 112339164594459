import Auth from '@aws-amplify/auth';
import React from 'react';
import ReactGA from 'react-ga';

const AuthContext = React.createContext({
  isAuthenticated: false,
  isAuthenticating: true,
  userInfo: null,
  session: null,
  handleLogin: (user: string, password: string) => {
    console.error('Failed to load Authorization context');
  },
  handleLogout: () => {
    console.error('Failed to load Authorization context');
  },
});

export interface AuthAwareProps {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  userInfo: any;
  session: any;
  handleLogin: (username: string, password: string) => void;
  handleLogout: () => void;
}

export function withAuth<P extends AuthAwareProps>(Component: React.ComponentType<P>) {
  return function AuthComponent(props: Pick<P, Exclude<keyof P, keyof AuthAwareProps>>) {
    return (
      <AuthConsumer>
        {({ isAuthenticated, isAuthenticating, userInfo, session, handleLogin, handleLogout }) => (
          <Component
            {...(props as P)}
            isAuthenticated={isAuthenticated}
            isAuthenticating={isAuthenticating}
            userInfo={userInfo}
            session={session}
            handleLogin={handleLogin}
            handleLogout={handleLogout}
          />
        )}
      </AuthConsumer>
    );
  };
}

interface AuthProviderProps {}

interface AuthProviderState {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  userInfo: any;
  session: any;
}

class AuthProvider extends React.Component<AuthProviderProps, AuthProviderState> {
  constructor(props: AuthProviderProps) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      userInfo: null,
      session: null,
    };
  }

  public componentDidMount = async () => {
    try {
      const session = await Auth.currentSession();
      if (session) {
        const userInfo = await Auth.currentUserInfo();

        ReactGA.set({ userId: userInfo.username });
        this.setState({
          isAuthenticated: true,
          isAuthenticating: false,
          userInfo,
          session,
        });
      } else {
        this.setState({
          isAuthenticating: false,
        });
      }
    } catch (e) {
      if (e !== 'No current user') {
        console.error(e);
      }
      this.setState({
        isAuthenticating: false,
      });
    }
  };

  public handleLogin = async (user: string, password: string) => {
    await Auth.signIn(user, password);
    const userInfo = await Auth.currentUserInfo();
    const session = await Auth.currentSession();

    ReactGA.set({ userId: userInfo.username });
    this.setState({
      isAuthenticated: true,
      userInfo,
      session,
    });
  };

  public handleLogout = () => {
    Auth.signOut().then(() => {
      this.setState({
        isAuthenticated: false,
        userInfo: null,
        session: null,
      });
    });
  };

  public render() {
    return (
      <AuthContext.Provider
        value={{
          isAuthenticated: this.state.isAuthenticated,
          isAuthenticating: this.state.isAuthenticating,
          userInfo: this.state.userInfo,
          session: this.state.session,
          handleLogin: this.handleLogin,
          handleLogout: this.handleLogout,
        }}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer };
