import { Theme } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { DataAwareProps, withData } from 'components/DataContext';
import Page from 'components/Page';
import UnderConctructionImage from 'images/312124-03a9f4.svg';
import { RouteComponentProps } from 'react-router';

const styles = (theme: Theme) => createStyles({});

interface HomeProps extends WithStyles<typeof styles>, RouteComponentProps<{}>, DataAwareProps {
  match: any;
}

@withData
class Home extends React.Component<HomeProps> {
  public render() {
    //    const { classes } = this.props;

    return (
      <Page disableCrumbs={false} title="Hogge Engineering | Research & Development">
        <Grid container={true} spacing={5} alignItems="center" justify="center" direction="column">
          <Grid item={true}>
            <img width={300} src={UnderConctructionImage} alt="" />
          </Grid>
          <Grid item={true}>
            <Typography variant="h3" color="primary" align="center" paragraph={true}>
              Research and Development
            </Typography>
          </Grid>
          <Grid item={true}>
            <Typography color="textPrimary" align="center" variant="body2">
              Please check back later.
            </Typography>
          </Grid>
        </Grid>
      </Page>
    );
  }
}

export default withStyles(styles)(Home);
