import React from 'react';
import { Redirect, Route } from 'react-router';
import { AuthConsumer } from './AuthContext';

//@ts-ignore
export default ({ component: Component, navProps: nProps, ...rest }) => (
  <AuthConsumer>
    {({ isAuthenticated, isAuthenticating }) => (
      <Route
        render={props =>
          isAuthenticated ? (
            <Component {...props} {...nProps} />
          ) : (
            <Redirect to={{ pathname: '/login', state: { referrer: props.location } }} />
          )
        }
        {...rest}
      />
    )}
  </AuthConsumer>
);
