import { Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import React from 'react';

import { HelpAwareProps, withHelp } from 'components/HelpContext';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
  });

interface HelpDialogProps extends WithStyles<typeof styles>, HelpAwareProps {}

class HelpDialog extends React.Component<HelpDialogProps> {
  public handleClose = () => {
    this.props.handleHelp(false);
  };

  public render() {
    const supportEmail = 'support@hogge.engineering';
    const supportEmailLink = 'mailto:' + supportEmail;
    return (
      <Dialog
        open={this.props.helpActive}
        aria-labelledby="help-dialog-title"
        aria-describedby="help-dialog-description">
        <DialogTitle id="help-dialog-title">{'Live Help'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="help-dialog-description">
            Live help is not currently available. For immediate assistance, please email{' '}
            <Link href={supportEmailLink}>{supportEmail}</Link> or call your project specialist.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary" autoFocus={true}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withHelp(withStyles(styles)(HelpDialog));
