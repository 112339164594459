import { observable } from 'mobx';

export class SnackbarMessage {
  public key: string;
  public message: string;

  constructor() {
    this.key = '';
    this.message = '';
  }
}

export default class Snackbar {
  @observable public open: boolean;
  @observable public messageInfo: SnackbarMessage;

  public queue: SnackbarMessage[];

  constructor() {
    this.open = false;
    this.messageInfo = new SnackbarMessage();
    this.queue = [];
  }

  public enqueue = (msg: string, key?: string) => {
    this.queue.push({
      message: msg,
      key: key !== undefined ? key : new Date().toString(),
    });

    if (this.open === true) {
      this.open = false;
    } else {
      this.processQueue();
    }
  };

  public processQueue = () => {
    if (this.queue.length > 0) {
      this.messageInfo = this.queue.shift() || this.messageInfo;
      this.open = true;
    }
  };

  public onClose = () => {
    this.open = false;
  };

  public onExited = () => {
    this.processQueue();
  };
}
