import { Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { createStyles, withStyles } from '@material-ui/styles';
import Hogge from 'components/Hogge';
import EmailLink from 'components/Links/EmailLink';
import HyperLink from 'components/Links/HyperLink';
import Page from 'components/Page';
import React from 'react';

const styles = (theme: Theme) => createStyles({});

interface TermsOfServiceProps {}

class TermsOfService extends React.Component<TermsOfServiceProps> {
  public render() {
    return (
      <Page disableCrumbs={true} title="Hogge Engineering LLC | Terms of Use">
        <Typography variant="h4" color="primary" align="center" paragraph={true}>
          Terms of Use
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true}>
          Each time you use or cause access to hogge.engineering (this “Site”), you agree to be
          bound by the following terms and conditions (“Terms of Use”), as same may be amended from
          time to time with or without notice to you. Please read these Terms of Use carefully
          before using this Site. Your use of this Site will constitute your agreement to comply
          with these rules. If you do not agree to these Terms of Use, you should not use this Site.
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true}>
          This Site and services provided to you on and through this Site is on an “AS IS” basis.
          You agree that the owner of this Site, Hogge Engineering LLC, and its affiliates and
          subsidiaries (collectively, “<Hogge to="/" />
          ,” “Hogge Engineering,” “Hogge,” “The Hogges,” “we,” “us,” “our,” “We,” “Us,” or “Our”),
          exclusively reserves the right and may, at any time and without notice and any liability
          to you, modify or discontinue this Site and its services or delete the data you provide,
          whether temporarily or permanently. This Site shall have no responsibility or liability
          for the timeliness, deletion, failure to store, inaccuracy, or improper delivery of any
          data or information.
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true}>
          By agreeing to the Terms of Use, you agree to the terms of Hogge Engineering’s{' '}
          <HyperLink to="/legal/privacy-policy" text="Privacy Policy" />, which is expressly
          incorporated into these Terms of Use. All personal data provided to Hogge Engineering as a
          result of your use of this Site will be handled in accordance with Hogge Engineering’s
          Privacy Policy.
        </Typography>
        <Typography variant="h5" color="textPrimary" paragraph={true}>
          Content
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true}>
          All content and material, including but not limited to, documents; designs; logos;
          graphics; artwork; images; photographs; audio clips; video clips; databases; text; HTML
          and other material on this Site, including the selection and arrangement thereof;
          copyrights; trademarks; service marks; and tradenames (collectively, the “Content”) is the
          sole property of Hogge Engineering, unless otherwise indicated, and is legally protected
          under U.S. Federal and State, as well as foreign, laws, regulations, and treaties,
          including applicable copyright, trademark, and other intellectual property laws. Except as
          otherwise explicitly agreed in writing, the content available on this Site may only be
          downloaded, displayed, reformatted and printed for your personal, non-commercial use,
          provided that (a) you do not modify the Content; (b) you retain any and all copyright and
          other proprietary notices contained in the Content; and (c) you do not copy, post,
          distribute, or transmit the Content on any other website or via any network computer, or
          broadcast the Content in any media. You may not reproduce, retransmit, distribute,
          disseminate, sell, publish, frame, broadcast, or circulate the content available on this
          Site to anyone, including but not limited to, others in the same company or organization,
          without Hogge Engineering’s express prior written consent.
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true}>
          Certain sections of or pages on this Site may contain separate terms and conditions, which
          are in addition to these terms and conditions. You should read those additional terms and
          conditions carefully. By accessing such sections or pages, you agree to be bound by those
          additional terms and conditions. In the event of a conflict, those additional terms and
          conditions will govern your use of those sections or pages.
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true}>
          Unauthorized use of this Site and its related systems, including but not limited to,
          unauthorized entry into Hogge Engineering’s systems or misuse of any information
          therefrom, is strictly prohibited. You may not use this Site in any manner that could
          damage, disable, overburden, or impair any Hogge Engineering site or service or interfere
          with any other party’s use and enjoyment of any Hogge Engineering site or service. You may
          not attempt to gain unauthorized access to any Hogge Engineering site or service, computer
          systems, or networks connected to any Hogge Engineering site or service, through hacking,
          password mining, or any other means. You agree that you will not engage in any activities
          related to this Site that are contrary to applicable laws or regulations.
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true}>
          Any applicable software you may download from or access on this Site, including but not
          limited to, any files, images, or data accompanying the software (collectively, the
          “Software”) are licensed to you by Hogge Engineering for the limited use described herein.
          Hogge Engineering transfers no right, title, or interest in the Software to you. Hogge
          Engineering retains full and complete title to the Software, and all intellectual property
          rights therein. You may not redistribute, sell, decode, reverse engineer, disassemble, or
          otherwise reduce the Software to a human perceivable form.
        </Typography>
        <Typography variant="h5" color="textPrimary" paragraph={true}>
          Content You Submit to this Site; Conduct
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true}>
          In using this Site or any service provided hereon, you may have the opportunity to add,
          create, upload, submit, distribute, post, share, and/or exchange information, data, text,
          software, or other materials on or through this Site, including but not limited to, any
          electronic e-mail communication (“User Content”). In doing so, you expressly grant, and
          represent and warrant that you have the right to grant, to Hogge Engineering a
          non-exclusive, irrevocable, worldwide, transferable, royalty-free, and perpetual license
          to use, publicly display, publicly perform, reproduce, distribute, sublicense, and create
          derivative works from your User Content in any manner or through any media now known or
          later developed, without any payment to you. You further acknowledge and agree that Hogge
          Engineering shall have the right (but not the obligation), in its sole discretion, to
          pre-screen, edit, refuse, move, or remove any User Content posted to this Site at any time
          and for any reason, with or without notice.
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true} component={'span'}>
          You agree that you will not use this Site to:
          <ul>
            <li>
              Upload, post, e-mail, or otherwise transmit any User Content that is unlawful,
              harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene,
              offensive, libelous, invasive of another’s privacy, hateful, or otherwise
              objectionable;
            </li>
            <li> Engage in or facilitate harassing conduct aimed at any other person or entity;</li>
            <li>
              Impersonate any person or entity or falsely state or otherwise misrepresent your
              affiliation with a person or entity;
            </li>
            <li>
              Forge headers or otherwise manipulate identifiers in order to disguise the origin of
              any content transmitted through or in connection with this Site;
            </li>
            <li>
              Upload, post, e-mail, or otherwise transmit any User Content that you do not have a
              right to transmit under any law or contractual or fiduciary relationships (e.g.,
              inside information, or proprietary and confidential information learned or disclosed
              as part of employment relationships or under nondisclosure agreements);
            </li>
            <li>
              Upload, post, e-mail, or otherwise transmit any User Content that infringes any
              patent, trademark, trade secret, copyright, or other proprietary rights of any party;
            </li>
            <li>
              Upload, post, e-mail, or otherwise transmit any unsolicited or unauthorized
              advertising, promotional materials, or any other form of solicitation;
            </li>
            <li>
              Upload, post, e-mail, or otherwise transmit any material that contains software
              viruses or any other computer code, files, or programs designed to interrupt, destroy,
              or limit the functionality of any computer software or hardware or telecommunications
              equipment;
            </li>
            <li>
              Interfere with or disrupt this Site, or servers or networks connected to this Site, or
              disobey any requirements, procedures, policies or regulations of networks connected to
              this Site;
            </li>
            <li>
              {' '}
              Violate any applicable local, state, national or international law, or any regulations
              having the force of law;
            </li>
            <li>or Collect or harvest any data about other users.</li>
          </ul>
        </Typography>
        <Typography variant="h5" color="textPrimary" paragraph={true}>
          No Warranty
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true}>
          This Site disclaims any and all responsibility or liability for the accuracy, content,
          completeness, legality, reliability, operability, or availability of information or
          material on this Site. Your use of this Site is at your sole risk.
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true} component={'span'}>
          <Box fontWeight="fontWeightBold">
            THE CONTENT OF THIS WEBSITE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. HOGGE
            ENGINEERING MAKES NO REPRESENTATIONS, WARRANTIES, OR GUARANTEES OF ANY KIND WHATSOEVER,
            EXPRESS OR IMPLIED, IN CONNECTION WITH THESE TERMS AND CONDITIONS OR THE CONTENT OF THIS
            SITE, INCLUDING BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, OR NON-INFRINGEMENT, UNLESS SUCH REPRESENTATIONS AND WARRANTIES ARE
            NOT LEGALLY EXCLUDABLE. HOGGE ENGINEERING FURTHER DISCLAIMS ANY REPRESENTATION OR
            WARRANTY: (a) THAT THE CONTENT WILL MEET YOUR SPECIFIC REQUIREMENTS; (b) THAT THE
            AVAILABILITY OF THIS SITE OR THE CONTENT WILL BE UNINTERRUPTED, TIMELY, SECURE,
            ERROR-FREE, OR THAT THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THIS SITE WILL BE
            ACCURATE OR RELIABLE, (c) REGARDING THE QUALITY OF ANY GOODS OR SERVICES PURCHASED OR
            OBTAINED THROUGH HOGGE ENGINEERING OR ANY TRANSACTIONS ENTERED INTO WITH HOGGE
            ENGINEERING OR (d) THAT ANY ERRORS IN THIS SITE OR THE CONTENT WILL BE CORRECTED. ANY
            MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THIS SITE IS DONE AT YOUR OWN
            DISCRETION AND RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER
            SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OR USE OF ANY SUCH MATERIAL. YOU
            UNDERSTAND AND AGREE THAT HOGGE ENGINEERING DOES NOT PROVIDE PROFESSIONAL ADVICE OF ANY
            KIND AND THAT USE OF ANY INFORMATION OBTAINED ON THIS SITE IS SOLELY AT YOUR OWN RISK
            AND WITHOUT ANY LIABILITY OF ANY KIND TO THIS SITE. NO ORAL OR WRITTEN ADVICE OR
            INFORMATION GIVEN BY HOGGE ENGINEERING, ITS EMPLOYEES, LICENSEES, OR THE LIKE WILL
            CREATE A WARRANTY OR GUARANTY, NOR MAY YOU RELY ON ANY SUCH INFORMATION OR ADVICE.
          </Box>
        </Typography>
        <Typography variant="h5" color="textPrimary" paragraph={true}>
          Limitation of Liability
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true} component={'span'}>
          <Box fontWeight="fontWeightBold">
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT, TO THE FULL EXTENT PERMITTED BY APPLICABLE
            LAWS, IN NO EVENT WILL HOGGE ENGINEERING BE LIABLE FOR ANY DAMAGES, LOSSES, OR EXPENSES
            OF WHATEVER NATURE AND HOWEVER ARISING, INCLUDING WITHOUT LIMITATION, DIRECT OR
            INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES, AND LOSSES
            OR EXPENSES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE,
            DATA, OR OTHER INTANGIBLE LOSS, ARISING IN CONNECTION WITH THIS SITE OR USE THEREOF OR
            INABILITY TO USE BY ANY PARTY, OR IN CONNECTION WITH ANY FAILURE OR PERFORMANCE, ERROR,
            OMISSION, INTERRUPTION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS OR
            LINE OR SYSTEM FAILURE, EVEN IF HOGGE ENGINEERING, OR REPRESENTATIVES THEREOF, ARE
            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, LOSSES, OR EXPENSES. HYPERLINKS TO OTHER
            INTERNET RESOURCES ARE ACCESSED AT YOUR OWN RISK AND IT IS YOUR RESPONSIBILITY TO TAKE
            ALL PROTECTIVE MEASURES TO GUARD AGAINST VIRUSES OR OTHER DESTRUCTIVE ELEMENTS. THE
            CONTENT, ACCURACY, OPINIONS EXPRESSED, AND OTHER LINKS PROVIDED BY THESE RESOURCES ARE
            NOT ENDORSED BY HOGGE ENGINEERING. THIS LIMITATION OF LIABILITY APPLIES WHETHER THE
            ALLEGED LIABILITY IS BASED ON CONTRACT, NEGLIGENCE, TORT, STRICT LIABILITY, OR ANY OTHER
            BASIS.
          </Box>
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true}>
          Some jurisdictions do not allow the exclusion of implied warranties, or certain kinds of
          limitations or exclusions of liability, so the limitations and exclusions set out in these
          Terms of Use may not apply to you. In such a case the limitations and exclusions set out
          in these Terms of Use shall apply to the fullest extent permitted by the laws of such
          applicable jurisdictions.
        </Typography>
        <Typography variant="h5" color="textPrimary" paragraph={true}>
          Indemnification
        </Typography>

        <Typography variant="body2" color="textPrimary" paragraph={true}>
          You agree to defend, indemnify, and hold harmless Hogge Engineering, its subsidiaries,
          affiliates, officers, directors, agents, partners, employees, and attorneys for any loss,
          demand, liability, claim, damages, costs, or expenses, including reasonable attorneys’
          fees, resulting from any third party claim, action, or demand arising out of or related to
          (a) your access to and use of this Site; (b) your breach or violation of any term,
          condition, representation, or warranty contained in these Terms of Use; and (c) your
          violation of state, federal, or foreign securities laws or regulations or any other
          person’s rights, including but not limited to, infringement of any intellectual property,
          proprietary, or privacy right of any third party.
        </Typography>
        <Typography variant="h5" color="textPrimary" paragraph={true}>
          Changes to these Terms and Conditions
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true}>
          Hogge Engineering reserves the right, at any time and from time to time, for any reason,
          in its sole discretion, to change the terms and conditions of these Terms of Use, or to
          change or discontinue any aspect or feature of this Site, including but not limited to,
          its content, hours of availability, or the equipment needed for access or use. This Site
          will notify visitors of the existence and location of the new or revised policy, the sole
          notice of which shall be posting the new or revised policy on this Site. Any changes to
          the Terms of Use will be binding and effective immediately upon posting on this Site. Your
          continued access to and use of this Site shall constitute your acceptance and agreement to
          be bound by any such changed terms. You should check back frequently and review the terms
          and conditions of these Terms of Use regularly so that you are aware of the most current
          rights and obligations that apply to you and the terms and conditions of your agreement
          with Hogge Engineering. No employee, contractor, agent, or representative of Hogge
          Engineering or its subsidiaries or affiliates is authorized to alter or amend these terms
          and conditions except by means of a written document executed by an authorized officer of
          Hogge Engineering.
        </Typography>
        <Typography variant="h5" color="textPrimary" paragraph={true}>
          Governing Law
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true}>
          These terms and conditions, your rights and obligations, and all actions contemplated by
          these Terms of Use shall be governed by and construed in accordance with the laws of the
          State of California, USA, without regard to its conflicts of law provisions. All claims
          arising out of or relating to these Terms of Use will be litigated exclusively in the
          federal or state courts of San Diego County, California, USA, and all parties consent to
          personal jurisdiction in those courts with respect to any action.
        </Typography>
        <Typography variant="h5" color="textPrimary" paragraph={true}>
          Regulatory Disclosures
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true}>
          The Content and information contained on this Site is proprietary to Hogge Engineering and
          prepared for informational purposes only. Nothing on this Site or in these Terms of Use
          should be regarded as a recommendation, advice, offer, or solicitation to buy or sell any
          product or service; an official confirmation of any transaction; an official valuation; or
          an official statement of Hogge Engineering. Hogge Engineering does not endorse or sponsor
          any particular security or market participant.
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true}>
          Prior to the execution of a purchase or sale of any security or investment, you are
          advised to consult with your banker, financial advisor, or other relevant professionals
          (e.g., legal, tax, and/or accounting counsel). Hogge Engineering shall not be liable for
          any investment decisions based upon, or results obtained from, the Content provided on
          this Site. Nothing contained on this Site is intended or construed to be legal, tax,
          accounting, or investment advice. It is the responsibility of the individual user to
          consider his or her legal and regulatory position in the relevant jurisdiction, the risks
          associated with trading such securities or investments, and to verify that any use of the
          Content, or subsequent investment decisions, do not contravene any restrictions,
          applicable laws, or regulations of any jurisdiction.
        </Typography>
        <Typography variant="h5" color="textPrimary" paragraph={true}>
          Miscellaneous
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true}>
          Any failure by Hogge Engineering to enforce any provision of these Terms of Use shall not
          be considered a waiver of the same or any other of Hogge Engineering’s rights under these
          Terms of Use, at law, or in equity.
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true}>
          If any portion of these Terms of Use is held to be unenforceable, then the remaining
          clauses and provisions will remain in full force and effect. Any and all warranties,
          provisions, rights, and obligations of the parties herein described and agreed to be
          performed subsequent to the termination of these Terms of Use shall survive the
          termination of these Terms of Use.
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true}>
          These Terms of Use, including Hogge Engineering’s Privacy Policy and any additional terms
          and conditions contained on this Site, constitute the entire agreement between you and
          Hogge Engineering, superseding any prior agreements between you and Hogge Engineering with
          regard to your use of this Site. All of Hogge Engineering’s rights and obligations under
          these Terms of Use are freely assignable by Hogge Engineering in connection with a merger,
          acquisition, or sale of Hogge Engineering’s assets.
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true}>
          Section titles and headings throughout these Terms of Use are for convenience only and
          have no legal or contractual effect.
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true} component={'span'}>
          If you have any questions regarding these Terms of Use, please contact us at{' '}
          <EmailLink user={'legal'} />.
        </Typography>
      </Page>
    );
  }
}

export default withStyles(styles)(TermsOfService);
