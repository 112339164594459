import { blue, grey } from '@material-ui/core/colors';
import createMuiTheme, { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

export default function createAppTheme(options: ThemeOptions) {
  return createMuiTheme({
    palette: {
      primary: {
        main: blue[600],
      },
      secondary: {
        main: grey[700],
      },
    },
    ...options,
  });
}
