import React from 'react';

const HelpContext = React.createContext({
  helpActive: false,
  handleHelp: (open: boolean) => {
    console.error('Failed to load Help context');
  },
});

export interface HelpAwareProps {
  helpActive: boolean;
  handleHelp: (open?: boolean) => void;
}

export function withHelp<P extends HelpAwareProps>(Component: React.ComponentType<P>) {
  return function HelpComponent(props: Pick<P, Exclude<keyof P, keyof HelpAwareProps>>) {
    return (
      <HelpConsumer>
        {({ helpActive, handleHelp }) => (
          <Component {...(props as P)} helpActive={helpActive} handleHelp={handleHelp} />
        )}
      </HelpConsumer>
    );
  };
}

interface HelpProviderProps {}

interface HelpProviderState {
  helpActive: boolean;
}

class HelpProvider extends React.Component<HelpProviderProps, HelpProviderState> {
  constructor(props: HelpProviderProps) {
    super(props);

    this.state = {
      helpActive: false,
    };
  }

  public handleHelp = (open?: boolean) => {
    this.setState({
      helpActive: open !== undefined ? open : !this.state.helpActive,
    });
  };

  public render() {
    return (
      <HelpContext.Provider
        value={{
          helpActive: this.state.helpActive,
          handleHelp: this.handleHelp,
        }}>
        {this.props.children}
      </HelpContext.Provider>
    );
  }
}

const HelpConsumer = HelpContext.Consumer;

export { HelpProvider, HelpConsumer };
