// @ts-nocheck
import { Theme } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';

import React from 'react';

import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const styles = (theme: Theme) =>
  createStyles({
    nested: {
      paddingLeft: theme.spacing(4),
    },
  });

interface NavListItemProps extends WithStyles<typeof styles> {
  to?: string;
  title: string;
  icon: string;
  onClick?: any;
  nested?: boolean;
}

class NavListItem extends React.Component<NavListItemProps> {
  public render() {
    if (this.props.to) {
      return this.renderAsLink();
    } else {
      return this.renderAsText();
    }
  }

  public renderAsText() {
    const { title, icon, children, onClick } = this.props;
    return (
      <ListItem button={true} key={title} onClick={onClick}>
        <ListItemIcon>
          <img width={48} alt="" src={icon} />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography color="primary" variant="button">
              {title}
            </Typography>
          }
        />
        {children}
      </ListItem>
    );
  }
  public renderAsLink() {
    const { classes, title, icon, nested } = this.props;
    const to = this.props.to ? this.props.to : '/';
    return (
      <ListItem
        button={true}
        component={RouterLink}
        to={to}
        key={title}
        className={nested ? classes.nested : ''}>
        <ListItemIcon>
          <img width={48} alt="" src={icon} />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography color="primary" variant="button">
              {title}
            </Typography>
          }
        />
      </ListItem>
    );
  }
}

export default withStyles(styles)(NavListItem);
