import { Theme } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import React from 'react';

import Paper from '@material-ui/core/Paper';

import CrumbsBar from 'components/CrumbsBar';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: 'auto',
      margin: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      [theme.breakpoints.up('md')]: {
        width: 900,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      [theme.breakpoints.up('lg')]: {
        width: 1200,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
      color: theme.palette.text.secondary,
    },
  });

interface SettingsProps extends WithStyles<typeof styles> {}

interface SettingsState {}

class Settings extends React.Component<SettingsProps, SettingsState> {
  public render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <CrumbsBar />
        <Paper className={classes.paper} />
      </div>
    );
  }
}

export default withStyles(styles)(Settings);
