import { Theme } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const styles = (theme: Theme) =>
  createStyles({
    a: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      link: theme.palette.primary.main,
      visited: theme.palette.primary.main,
      hover: theme.palette.primary.light,
      active: theme.palette.primary.dark,
    },
  });

interface HyperLinkProps extends WithStyles<typeof styles> {
  to: string;
  color?: TypographyProps['color'];
  variant?: TypographyProps['variant'];
  text?: string;
  TypographyClasses?: any;
  button?: any;
}

class HyperLink extends React.Component<HyperLinkProps> {
  private url: string;
  constructor(props: HyperLinkProps) {
    super(props);
    const { to } = this.props;
    this.url = to;
  }

  public getName() {
    const { text } = this.props;
    return text ? text : this.url;
  }

  public renderChildren() {
    const { children, button, variant, color, TypographyClasses } = this.props;
    if (children) {
      return children;
    } else if (button || variant || color || TypographyClasses) {
      return this.renderTypography();
    } else if (button) {
      return this.renderButton();
    } else {
      return this.getName();
    }
  }

  public renderButton() {
    return <IconButton aria-label="{this.getName()}">{this.getName()}</IconButton>;
  }
  public renderTypography() {
    const { variant, color, TypographyClasses } = this.props;
    return (
      <IconButton aria-label="{this.getName()}">
        <Typography
          variant={variant}
          color={color}
          className={classNames(TypographyClasses)}
          component="span"
          noWrap={true}>
          {this.getName()}
        </Typography>
      </IconButton>
    );
  }

  public render() {
    if (false && window.location.pathname === this.url) {
      //need to somehow monitor window.location for this, otherwise it wont change if u route away with tabs
      return this.renderChildren();
    } else {
      return (
        <RouterLink to={this.url} className={this.props.classes.a}>
          {this.renderChildren()}
        </RouterLink>
      );
    }
  }
}

export default withStyles(styles)(HyperLink);
