import { Theme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import NotFoundImage from 'images/312124-03a9f4.svg';
import React from 'react';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: 'auto',
      margin: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      [theme.breakpoints.up('md')]: {
        width: 900,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      [theme.breakpoints.up('lg')]: {
        width: 1200,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    grid: {
      marginTop: theme.spacing(3),
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
      color: theme.palette.text.secondary,
    },
    icon: {
      fontSize: 64,
      color: theme.palette.primary.main,
    },
  });

interface NotFoundProps extends WithStyles<typeof styles> {}

class NotFound extends React.Component<NotFoundProps> {
  public render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid
          container={true}
          spacing={5}
          alignItems="center"
          justify="center"
          direction="column"
          className={classes.grid}>
          <Grid item={true}>
            <img width={300} src={NotFoundImage} alt="" className={classes.icon} />
          </Grid>
          <Grid item={true}>
            <Typography variant="h3" color="primary" align="center" paragraph={true}>
              Page Not Found
            </Typography>
          </Grid>
          <Grid item={true}>
            <Typography color="textPrimary" align="center" variant="body2">
              Whatever you're looking, it's nowhere to be found.
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(NotFound);
