import React from 'react';
import ReactGA from 'react-ga';
import { RouteComponentProps, withRouter } from 'react-router';

interface TrackerProps extends RouteComponentProps<{}> {}

class Tracker extends React.Component<TrackerProps> {
  private unListen: any;
  constructor(props: TrackerProps) {
    super(props);

    this.unListen = this.props.history.listen((location, action) => {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    });
  }

  public componentWillUnmount = () => {
    this.unListen();
  };

  public render() {
    return this.props.children;
  }
}

export default withRouter(Tracker);
