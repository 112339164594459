import { Theme } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { DataAwareProps, withData } from 'components/DataContext';
import Page from 'components/Page';
import { observer } from 'mobx-react';
import numeral from 'numeral';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

numeral.defaultFormat('0,000');

const styles = (theme: Theme) =>
  createStyles({
    tabs: {
      margin: `${theme.spacing(2)}px ${theme.spacing(-3)}px ${theme.spacing(3)}px`,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    tab: {
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
      },
    },
    divider: {
      marginBottom: theme.spacing(6),
    },
  });

interface ProjectPageProps
  extends WithStyles<typeof styles>,
    RouteComponentProps<{}>,
    DataAwareProps {
  match: any;
  tabIndex: number;
}

@withData
@observer
class ProjectPage extends React.Component<ProjectPageProps> {
  public render() {
    if (window.logRenders) {
      console.log('Rendering ProjectPage');
    }

    const { data, match } = this.props;
    const project = data.getProject(match.params.projectId);
    return <Page>Future home of the {project.name} project.</Page>;
  }
}

export default withRouter(withStyles(styles)(ProjectPage));
