import { action, observable } from 'mobx';
import Connection from './Connection';
import Project from './Project';
import Snackbar from './Snackbar';

class GlobalState {
  @observable public drawerOpen = false;
  @observable public legalMenuExpanded = false;
}

export default class Store {
  @observable public GlobalState = new GlobalState();
  @observable public snackbar = new Snackbar();
  public connection = new Connection(this);

  @observable public projects = new Map();

  constructor() {
    //@ts-ignore
    window.store = this;
    this.registerProject(new Project(this, 'San Diego Airport', 'San Diego, CA'));
    this.registerProject(new Project(this, 'San Diego Zoo', 'San Diego, CA'));
    this.registerProject(new Project(this, 'San Diego Mental Ward', 'San Diego, CA'));

    const FB = new Project(this, 'Fallbrook Castle', 'San Diego, CA');
    FB.startTime = 1619852400;
    FB.estimatedTime = 1651388400;
    this.registerProject(FB);
  }

  public getProjectsArray = () => {
    return Array.from(this.projects)
      .map((item: any, index: number) => item[1])
      .sort((a, b) => {
        return ('' + a.name).localeCompare(b.name);
      });
  };
  public getProject = (projectPath: string) => {
    console.log('Looking for project at path: ' + projectPath);
    return this.projects.get(projectPath);
  };

  //
  // END OF USEFUL THINGS
  //

  public handleLogin = () => {
    this.connection.handleLogin();
  };

  public handleLogout = () => {
    this.connection.handleLogout();
    //TODO:  DELETE ALL DATA FROM STORE
  };

  @action.bound public handleLog = (data: any) => {
    if (window.logStoreUpdates) {
      console.log('Received log update: ', data);
    }
  };

  @action.bound public handleUpdate = (data: any) => {
    if (window.logStoreUpdates) {
      console.log('Updating store: ', data);
    }
  };

  private registerProject(project: any) {
    this.projects.set(project.getPathName(), project);
    //console.log('Setting project for path: ' + project.getPathName());
  }
}
