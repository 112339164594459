import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import 'typeface-roboto';

import createAppTheme from 'components/AppTheme';
import { AuthAwareProps, withAuth } from 'components/AuthContext';
import Footer from 'components/Footer';
import HelpDialog from 'components/HelpDialog';
import NavBar from 'components/NavBar';
import ProtectedRoute from 'components/ProtectedRoute';
import QueuedSnackbar from 'components/QueuedSnackbar';
import ScrollToTop from 'components/ScrollToTop';
import CareersPage from 'pages/Careers/CareersPage';
import Home from 'pages/Home/Home';
import PrivacyPolicy from 'pages/Legal/PrivacyPolicy';
import TermsOfService from 'pages/Legal/TermsOfService';
import Login from 'pages/Login/Login';
import NotFound from 'pages/NotFound/NotFound';
import PressPage from 'pages/Press/PressPage';
import ProjectPage from 'pages/Projects/ProjectPage';
import ProjectsPage from 'pages/Projects/ProjectsPage';
import ResearchPage from 'pages/Research/ResearchPage';
import Settings from 'pages/Settings/Settings';
import './App.css';

const theme = createAppTheme({});

interface AppProps extends AuthAwareProps {}

class App extends React.Component<AppProps> {
  public render() {
    return (
      !this.props.isAuthenticating && (
        <div className="app">
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <NavBar />
            <HelpDialog />
            <QueuedSnackbar />
            <div className="content">
              <ScrollToTop>
                <Switch>
                  <Route path="/login" exact={true} component={Login} />
                  <Route path="/" exact={true} component={Home} navProps={{}} />
                  <ProtectedRoute
                    path="/settings"
                    exact={true}
                    component={Settings}
                    navProps={{}}
                  />
                  <ProtectedRoute
                    path="/projects"
                    exact={true}
                    component={ProjectsPage}
                    navProps={{ tabIndex: 0 }}
                  />
                  <Route
                    path="/research"
                    exact={true}
                    component={ResearchPage}
                    navProps={{ tabIndex: 0 }}
                  />
                  <Route
                    path="/careers"
                    exact={true}
                    component={CareersPage}
                    navProps={{ tabIndex: 0 }}
                  />
                  <Route
                    path="/press"
                    exact={true}
                    component={PressPage}
                    navProps={{ tabIndex: 0 }}
                  />
                  <Route
                    path="/projects/:projectId"
                    exact={true}
                    component={ProjectPage}
                    navProps={{ tabIndex: 0 }}
                  />

                  <Route path="/legal/terms-of-use" exact={true} component={TermsOfService} />
                  <Route path="/legal/privacy-policy" exact={true} component={PrivacyPolicy} />
                  <Route component={NotFound} />
                </Switch>
              </ScrollToTop>
            </div>
            <Footer />
          </MuiThemeProvider>
        </div>
      )
    );
  }
}

export default withAuth(App);
