import Typography from '@material-ui/core/Typography';
import Hogge from 'components/Hogge';
import EmailLink from 'components/Links/EmailLink';
import Page from 'components/Page';
import React from 'react';

interface PrivacyPolicyProps {}

class PrivacyPolicy extends React.Component<PrivacyPolicyProps> {
  public render() {
    return (
      <Page disableCrumbs={true} title="Hogge Engineering | Online Privacy Policy Statement">
        <Typography variant="h4" color="primary" align="center" paragraph={true}>
          Online Privacy Policy Statement
        </Typography>
        <Typography variant="h5" color="textPrimary" paragraph={true}>
          To whom and to what does this Online Privacy Policy apply?
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true}>
          This Online Privacy Policy (the “Policy”) applies to personally identifiable information
          (“personal information”) that Hogge Engineering LLC. and its affiliates and subsidiaries
          (collectively, “<Hogge to="/" />
          ,” “Hogge Engineering,” “Hogge,” “The Hogges,” “we,” “us,” “our,” “We,” “Us,” or “Our”)
          collects from or about you via this website (this “Site”). By using this Site, you agree
          with and consent to the Policy.
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true}>
          This Site may contain links to other websites, but this Online Privacy Policy does not
          apply to them and Hogge Engineering is not responsible for their content. If you visit
          external websites, we recommend that you review their privacy policies. If we materially
          change our privacy policies or practices, we will also amend this Policy to reflect such
          changes. You should review the Policy each time you visit this Site.
        </Typography>

        <Typography variant="h5" color="textPrimary" paragraph={true}>
          What personal and technical information do we collect via this Site?
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true}>
          This Site will collect limited personal information that you voluntarily provide, such as
          your name and email address. Providing this personal information represents your consent
          to Hogge Engineering’s collection and use of such information in accordance with the
          Policy.
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true}>
          This Site may also collect technical information regarding your computer and operating
          systems, such as your Internet Protocol address, domain name, and system configurations
          and settings.
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true}>
          Additionally, if you access a password protected portion of this Site, then (a) once you
          submit your password, this Site will recognize you and will collect all information that
          you submit, including all electronic instructions (such as transaction information), and
          (b) any information collected about you from this Site may be associated with other
          identifying information that we have about you.
        </Typography>

        <Typography variant="h5" color="textPrimary" paragraph={true}>
          What anonymous data is collected through this Site?
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true}>
          In order to collect the anonymous data described in the preceding paragraph, we may use
          temporary “cookies” that remain in the cookies file of your browser until the browser is
          closed. The IAB website at www.allaboutcookies.org tells you how to remove cookies from
          your browser. Further details about our cookie usage are provided below.
        </Typography>

        <Typography variant="h6" color="textPrimary" paragraph={true}>
          First party cookies
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true}>
          First party cookies are our own cookies, controlled by us and used to facilitate a smooth
          user experience.
        </Typography>

        <Typography variant="h6" color="textPrimary" paragraph={true}>
          Third party cookies
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true}>
          Third party cookies are those found in other companies’ internet tools, which we use to
          enhance or better understand this Site, and which are controlled by them.
        </Typography>

        <Typography variant="h5" color="textPrimary" paragraph={true}>
          How do we use, share, and disclose personal information that we collect via this Site?
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true}>
          Any personal information you provide and the personalized data you create and save on this
          Site are used to provide services to you and to inform you of products, services, or
          opportunities that may be available through Hogge Engineering. Such information and data
          will also be used to administer our business, this Site, and our services in a manner
          consistent with this Policy; the terms and conditions that govern your use of this Site;
          and all applicable laws, rules, regulations, or other legal obligations. If you provide us
          with your name, address, telephone number, and/or email address, or have done so in the
          past, Hogge Engineering may contact you by telephone, mail, and/or email. You may change
          your contact details or unsubscribe from mailing lists at any time by contacting Hogge
          Engineering support. Email or other electronic communications sent to us through this Site
          will be maintained in a manner consistent with our legal and regulatory requirements
          regarding member, client, and public communications.
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true}>
          We do not rent, sell, or share your personal information or personalized data to
          unaffiliated organizations except to provide products or services you have requested, when
          we have your permission, or under certain limited circumstances. For example, we provide
          such information to companies who work on behalf of or with Hogge Engineering subject to
          confidentiality agreements. These companies may use your personal information to help
          Hogge Engineering communicate with you about offers from Hogge Engineering. These
          companies do not, however, have any independent or legal right to share this information.
          We also use such information to respond to subpoenas, court orders, or other similar legal
          obligations and processes, or to establish or exercise our legal rights or defend against
          legal claims. In addition we will share such information if we believe it is necessary to
          do so in order to investigate, prevent, or take action regarding illegal activities,
          suspected fraud, situations involving potential threats to the physical safety of any
          person, violations of this Site’s Terms of Service, or as otherwise required by law.
          Finally, we may transfer information about you to any acquirer or successor of Hogge
          Engineering if and to the extent that Hogge Engineering is acquired by or merged with
          another company.
        </Typography>

        <Typography variant="h5" color="textPrimary" paragraph={true}>
          How do we maintain the confidentiality and security of the personal information that we
          collect via this Site?
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true}>
          Hogge Engineering maintains reasonable physical, electronic, and procedural safeguards
          that comply with applicable laws and regulations to protect personal information about
          you, and works with vendors and partners to protect the security and privacy of user
          information.
        </Typography>

        <Typography variant="h5" color="textPrimary" paragraph={true}>
          Can you edit my profile and change my communication preferences via this Site?
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true}>
          You may edit your contact information and communications preferences at any time by
          contacting Hogge Engineering support. Hogge Engineering reserves the right to send you
          certain communications relating to this Site, such as service announcements or
          administrative messages without offering you the opportunity to opt-out of receiving them.
        </Typography>

        <Typography variant="h5" color="textPrimary" paragraph={true}>
          Who should you contact if you have questions about this Policy?
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph={true}>
          If you have any questions regarding this Policy, or our privacy-related practices, please
          contact us at <EmailLink user={'legal'} />.
        </Typography>
      </Page>
    );
  }
}

export default PrivacyPolicy;
