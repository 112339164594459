import { Theme } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import CrumbsBar from 'components/CrumbsBar';
import React from 'react';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: 'auto',
      margin: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      [theme.breakpoints.up('md')]: {
        width: 900,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      [theme.breakpoints.up('lg')]: {
        width: 1200,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
      color: theme.palette.text.secondary,
    },
  });

interface PageProps extends WithStyles<typeof styles> {
  disableCrumbs?: boolean;
  title?: string;
}

class Page extends React.Component<PageProps> {
  public componentDidMount() {
    if (this.props.title) {
      document.title = this.props.title;
    }
  }

  public render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.props.disableCrumbs ? null : <CrumbsBar />}
        <div>{this.props.children}</div>
      </div>
    );
  }
}

export default withStyles(styles)(Page);
