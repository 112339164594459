//@ts-ignore
//import { merge } from 'lodash';
import { observable } from 'mobx';

export default class Project {
  public store: any;
  @observable public name = 'Unknown Project';
  @observable public address = 'Unknown Address';

  @observable public startTime: any = null;
  @observable public endTime: any = null;
  @observable public estimatedTime: any = null;

  constructor(store: any, name: string, address: string) {
    this.store = store;
    this.name = name;
    this.address = address;
  }

  public getPathName = () => {
    return this.name.replace(/\s+/g, '');
  };

  public getPath = () => {
    return '/projects/' + this.getPathName();
  };
}
