import { AuthAwareProps, withAuth } from 'components/AuthContext';
import { observer } from 'mobx-react';
import React from 'react';
import Store from 'util/Store/Store';

const DataContext = React.createContext({
  data: {},
});

export interface DataAwareProps {
  data?: any;
}

export function withData<P extends DataAwareProps>(Component: React.ComponentType<P>): any {
  return function DataComponent(props: Pick<P, Exclude<keyof P, keyof DataAwareProps>>) {
    return <DataConsumer>{({ data }) => <Component {...(props as P)} data={data} />}</DataConsumer>;
  };
}

interface DataProviderProps extends AuthAwareProps {
  //store?: any;
}

interface DataProviderState {}

@observer
class DataProvider extends React.Component<DataProviderProps, DataProviderState> {
  public data = new Store();

  constructor(props: DataProviderProps) {
    super(props);
    this.state = {};
  }

  public componentDidUpdate = async (
    prevProps: DataProviderProps,
    prevState: DataProviderState,
    snapshot: any
  ) => {
    //console.log('isAuthenticated:', this.props.isAuthenticated);
    if (this.props.isAuthenticated) {
      this.data.handleLogin();
    } else {
      this.data.handleLogout();
    }
  };

  public render() {
    return (
      <DataContext.Provider value={{ data: this.data }}>{this.props.children}</DataContext.Provider>
    );
  }
}

const DataConsumer = DataContext.Consumer;
const AuthenticatedDataProvider = withAuth(DataProvider);

export { AuthenticatedDataProvider as DataProvider, DataConsumer };
