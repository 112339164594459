import { Theme } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { DataAwareProps, withData } from 'components/DataContext';
import HoggeEngineeringLogo from 'images/hogge-engineering-logo-hc.svg';
import { observer } from 'mobx-react';
import React from 'react';

const styles = (theme: Theme) =>
  createStyles({
    nothing: {},
  });

interface HoggeToggleProps extends WithStyles<typeof styles>, DataAwareProps {
  size?: number;
}

@withData
@observer
class HoggeToggle extends React.Component<HoggeToggleProps> {
  public render() {
    const toggleDrawer = () => (event: any) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      //console.log("\n");
      //console.log("From HoggeToggle");
      //console.log("this.props.data.GlobalState.drawerOpen before: " + this.props.data.GlobalState.drawerOpen);
      this.props.data.GlobalState.drawerOpen = !this.props.data.GlobalState.drawerOpen;
      //console.log("this.props.data.GlobalState.drawerOpen after: " + this.props.data.GlobalState.drawerOpen);
      //console.log("\n");
      event.stopPropagation();
    };

    const sz = this.props.size ? this.props.size : 250;

    return (
      <IconButton onClick={toggleDrawer()}>
        <img width={sz} src={HoggeEngineeringLogo} alt="Navigate Hogge Engineering" />
      </IconButton>
    );
  }
}

export default withStyles(styles)(HoggeToggle);
